<template>
  <div>
    <!-- Start leftpart -->
    <div class="leftpart">
      <div class="leftpart_inner">
        <div class="logo">
          <router-link to="/"
            ><img src="../assets/img/logo/thisistheshop.png" alt=""
          /></router-link>
        </div>
        <!-- End .logo -->
        <div class="menu">
          <a
            v-on:click="activetab = 1"
            :class="[activetab === 1 ? 'active' : '']"
          >
            <img
              class="svg"
              src="../assets/img/svg/home-run.svg"
              alt="homerun"
            />
            <span class="menu_content">{{ $t("HomeLight.home") }}</span></a
          >
          <!-- End Home -->
          <a
            v-on:click="activetab = 2"
            :class="[activetab === 2 ? 'active' : '']"
          >
            <img
              class="svg"
              src="../assets/img/svg/briefcase.svg"
              alt="briefcase"
            />
            <span class="menu_content">{{
              $t("HomeLight.itemDetail")
            }}</span></a
          >
          <!-- End Item Detail -->
          <a
            v-on:click="activetab = 3"
            :class="[activetab === 3 ? 'active' : '']"
          >
            <img class="svg" src="../assets/img/svg/mail.svg" alt="mail" />
            <span class="menu_content"> {{ $t("HomeLight.contactUs") }}</span>
          </a>
          <!-- End Contact -->
          <DropDownMenu :list="langs" @menu-link="handleMenuLink" />
          <!-- End Lanuage -->
        </div>
        <div class="copyright">
          <p>
            &copy; {{ new Date().getFullYear() }}
            {{ $t("HomeLight.brandName") }}
          </p>
        </div>
        <!-- End copyright -->
      </div>
    </div>
    <!-- End leftpart -->

    <!-- Start rightpart -->
    <div class="rightpart">
      <div class="rightpart_in">
        <div class="tokyo_tm_section">
          <div class="container">
            <div v-if="activetab === 1" class="tabcontent">
              <Home />
            </div>
            <div v-if="activetab === 2" class="tabcontent">
              <ItemAll />
            </div>
            <div v-if="activetab === 3" class="tabcontent">
              <Contact />
            </div>
          </div>
          <!--Start Swithch Theme Button -->
          <div
            class="tokyo_tm_intro_fixed_price right"
            v-on:click="toggleTheme"
          >
            <span class="anim"></span><span class="anim"></span
            ><span class="anim"></span
            ><a class="pricing-info">{{ isLightTheme ? "Dark" : "Light" }}</a>
          </div>
          <!-- End Swithch Theme Button -->
        </div>
      </div>
    </div>
    <!-- End rightpart -->
  </div>
</template>

<script>
import Home from "../components/Home";
import ItemAll from "../components/ItemAll";
import Contact from "../components/Contact";
import DropDownMenu from "../components/DropDownMenu";
export default {
  components: {
    Home,
    ItemAll,
    Contact,
    DropDownMenu,
  },
  data() {
    return {
      activetab: 1,
      isLightTheme: true,
      langs: [
        {
          chi: "中",
          menuLink: function () {
            this.$i18n.locale = "chi";
          },
        },
        {
          en: "EN",
          menuLink: function () {
            this.$i18n.locale = "en";
          },
        },
      ],
    };
  },
  methods: {
    handleMenuLink(menuLink) {
      menuLink.call(this);
    },
    toggleTheme() {
      this.isLightTheme = !this.isLightTheme;
      if (this.isLightTheme) {
        document.body.classList.remove("dark");
      } else {
        document.body.classList.add("dark");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
