<template>
  <div class="dropDownMenu">
    <!-- Header Navigation Menu Icons -->
    <a @click="show = false" v-if="show">
      <img class="svg" src="../assets/img/svg/cancel.svg" alt="closeMenu" />
      <span
        class="dropdown__menu-item"
        v-for="(item, i) in list"
        :key="`item-${i}`"
        @click="emitMenuLink(Object.values(item)[1])"
      >
        {{ Object.values(item)[0] }}
      </span>
    </a>
    <a @click="show = true" v-else>
      <img class="svg" src="../assets/img/svg/language.svg" alt="openMenu" />
    </a> 
  </div>
</template>
    



<script>
import "../assets/scss/others/dropDownMenu.scss";
export default {
  props: {
    list: Array,
  },
  data() {
    return { show: false };
  },
  methods: {
    emitMenuLink(menuLink) {
      this.$emit("menu-link", menuLink);
    },
  },
};
</script>

<style lang="scss" scoped></style>
